html {
  height: 100%;
}

body {
  height: 100%;
  letter-spacing: $body-letter-spacing;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @if $enable-rtl {
    direction: rtl;
    text-align: right;
  }
}

b {
  font-weight: bold;
}

a {
  &:focus {
    outline: none;
  }
}

h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

h3 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

h4 {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
}

h5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

.p1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
}

.p4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4A4A4A;
}