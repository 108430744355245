.page-header {
	margin: 0 0 rem(15px);
	padding: 0;
	border: none;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	color: #3A3A3A;
	
	& small {
		line-height: rem(20px);
	}
}