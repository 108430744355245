.app-sidebar {
	width: $app-sidebar-width;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	padding-top: $app-header-height;
	background: var(--app-sidebar-bg);
	z-index: $app-sidebar-zindex;
	
	@if $enable-rtl {
		left: auto;
		right: 0;
	}
	@include media-breakpoint-down(md) {
		position: fixed;
		padding-top: 0;
		left: $app-sidebar-width * -1;
		z-index: $app-header-zindex + 1;
		
		@if $enable-rtl {
			left: auto;
			right: $app-sidebar-width * -1;
		}
	}
	
	& .menu {
		& .menu-profile {
			padding: rem(20px);
			color: $white;
			background: $gray-900;
			overflow: hidden;
			position: relative;
		
			& .menu-profile-link {
				margin: rem(-20px);
				padding: rem(20px);
				display: block;
				color: $white;
				font-weight: $font-weight-bold;
				text-decoration: none;
				
				&:hover {
					& .menu-profile-cover {
						&.with-shadow {
							&:before {
								opacity: 0.75;
							}
						}
					}
				}
			}
			& .menu-profile-image {
				width: rem(60px);
				height: rem(60px);
				margin-bottom: rem(12px);
				overflow: hidden;
				position: relative;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				display: flex;
				align-items: center;
				border-radius: $border-radius-lg * 5;
				
				& img {
					max-width: 100%;
					max-height: 100%;
				}
				&.menu-profile-image-icon {
					& i {
						float: none;
						display: block;
						font-size: rem(24px);
						text-align: center;
						width: auto;
						margin: 0 auto rem(-12px);
					}
				}
			}
			& .menu-profile-cover {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-image: $app-sidebar-profile-bg-image;
				background-repeat: no-repeat;
				background-size: cover;
			
				&.with-shadow {
					&:before {
						content: '';
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						background: rgba(var(--app-sidebar-profile-bg-rgb), .75);
						transition: all .2s linear;
					}
				}
			}
			& .menu-profile-info {
				font-size: $font-size-base;
				position: relative;
			
				& small {
					display: block;
					font-weight: $font-weight-base;
					color: rgba($white, .75);
					font-size: $font-size-sm;
					margin-bottom: rem(-5px);
				}
			}
		}
		& .menu-header {
			margin: 0;
			padding: rem(15px) rem(20px) rem(5px);
			line-height: rem(20px);
			font-size: rem(13px);
			color: rgba($white, .45);
			font-weight: $font-weight-bold;
		}
		& .menu-divider {
			height: 1px;
			background: $gray-900;
			margin: $app-sidebar-component-padding-y 0;
		}
		& .menu-caret {
			display: block;
			width: rem(20px);
			text-align: center;
			font-size: $font-size-sm;
			border: none;
		
			@include fontawesome();
			@if $enable-rtl {
				margin-right: auto;
			} @else {
				margin-left: auto;
			}
		
			&:before {
				content: '\f0da';
				display: block;
				text-align: center;
				opacity: 0.5;
				transition: all .2s linear;
				
				@if $enable-rtl {
					content: '\f0d9';
				}
			}
		}
		& .menu-search {
			padding: rem(20px);
			
			& + .menu-item {
				padding-top: 0;
			}
			& .form-control {
				background: none;
				border: none;
				padding-left: 0;
				padding-right: 0;
				border-bottom: 1px solid rgba($white, .2);
				color: $white;
				border-radius: 0;
				
				&:focus {
					border-color: rgba($white, .5);
					box-shadow: none;
				}
			}
		}
		
		& .active:not(.closed):not(.closing),
		& .expanding,
		& .expand {
			& > a {
				& .menu-caret {
					&:before {
						opacity: 1.0;
						transform: rotate(90deg);
						
						@if $enable-rtl {
							transform: rotate(-90deg);
						}
					}
				}
			}
		}
		& .menu-item {
			position: relative;
					
			& .menu-link {
				line-height: rem(20px);
				color: rgba($white, .6);
				text-decoration: none;
				padding-top: $app-sidebar-component-padding-y;
				padding-bottom: $app-sidebar-component-padding-y;
				display: flex;
				align-items: center;
				
				@if $enable-rtl {
					margin-left: $app-sidebar-component-padding-x;
					padding-left: $app-sidebar-component-padding-x;
					padding-right: $app-sidebar-component-padding-x * 2;
					border-radius: 24px 0 0 24px;
				}	@else {					
					margin-right: $app-sidebar-component-padding-x;
					padding-right: $app-sidebar-component-padding-x;
					padding-left: $app-sidebar-component-padding-x * 2;
					border-radius: 0 24px 24px 0;
				}
				
				& .menu-text {
					flex: 1;
				}
				& .menu-badge {
					padding: 0 $badge-padding-x * 1.5;
					background: $gray-900;
					font-weight: $font-weight-bold;
					font-size: rem(10px);
					border-radius: $border-radius-lg * 2;
				}
				& .menu-label {
					padding: $badge-padding-y $badge-padding-x;
					font-size: 75%;
					font-weight: $font-weight-bold;
					color: $white;
					text-align: center;
					white-space: nowrap;
					vertical-align: baseline;
					margin-left: rem(5px);
					background-color: $primary;
					border-radius: $border-radius-sm;
					
					@include gradient-enabled {
						background-image: var(--bs-gradient);
					}
					@if $enable-rtl {
						margin-left: 0;
						margin-right: rem(5px);	
					}
				}
				&:hover,
				&:focus {
					background: none;
					color: $white;
				}
				& .menu-caret {
					position: absolute;
					left: rem(5px);
				}
			}
			& .menu-icon {
				width: rem(24px);
				text-align: center;
				line-height: rem(24px);
				margin-top: rem(-2px);
				margin-bottom: rem(-2px);
				font-size: rem(18px);
				display: flex;
				align-items: center;
				justify-content: center;
				
				@if $enable-rtl {
					margin-left: rem(16px);
				} @else {
					margin-right: rem(16px);
				}
				& img {
					max-width: 100%;
					max-height: 100%;
				}
				& .material-icons,
				& [class^="ion-"] {
					font-size: rem(22px);
				}
			}
			& .menu-icon-img {
				margin-top: rem(-2px);
				margin-bottom: rem(-2px);
				width: rem(24px);
				height: rem(24px);
				overflow: hidden;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				border-radius: $border-radius;
				display: flex;
				align-items: center;
				
				@if $enable-rtl {
					margin-left: rem(10px);
				} @else {
					margin-right: rem(10px);
				}
				
				& img {
					max-width: 100%;
					max-height: 100%;
					display: block;
					margin: 0 auto;
				}
			}
			& .menu-submenu {
				padding: 0;
				margin: 0;
				position: relative;
				display: none;
				
				& .menu-item {
					& .menu-link {
						@if $enable-rtl {
							padding-right: $app-sidebar-component-padding-x + 40;
							padding-left: $app-sidebar-component-padding-x;
						} @else {
							padding-left: $app-sidebar-component-padding-x + 40;
							padding-right: $app-sidebar-component-padding-x;
						}
						
						& .menu-caret {
							@if $enable-rtl {
								right: $app-sidebar-component-padding-x * 6.667 - rem(22px);
							} @else {
								left: $app-sidebar-component-padding-x * 6.667 - rem(22px);
							}
						}
					}
					
					& .menu-submenu {
						& .menu-item {
							& .menu-link {
								@if $enable-rtl {
									padding-right: $app-sidebar-component-padding-x * 8;
									padding-left: $app-sidebar-component-padding-x;
								} @else {
									padding-left: $app-sidebar-component-padding-x * 8;
									padding-right: $app-sidebar-component-padding-x;
								}
								
								& .menu-caret {
									@if $enable-rtl {
										right: $app-sidebar-component-padding-x * 8 - rem(22px);
									} @else {
										left: $app-sidebar-component-padding-x * 8 - rem(22px);
									}
								}
							}
							
							& .menu-submenu {
								& .menu-item {
									& .menu-link {
										@if $enable-rtl {
											padding-right: $app-sidebar-component-padding-x * 9.333;
											padding-left: $app-sidebar-component-padding-x;
										} @else {
											padding-left: $app-sidebar-component-padding-x * 9.333;
											padding-right: $app-sidebar-component-padding-x;
										}
										& .menu-caret {
											@if $enable-rtl {
												right: $app-sidebar-component-padding-x * 9.333 - rem(22px);
											} @else {
												left: $app-sidebar-component-padding-x * 9.333 - rem(22px);
											}
										}
									}
								}
							}
						}
					}
				}
			}
			
			&.active {
				&.has-sub {
					& > .menu-submenu {
						display: block;
					}
				}
				& > .menu-link {
					& .menu-caret {
						color: $primary;
					}
				}
				&:not(.has-sub) {
					& > .menu-link {
						position: relative;
						z-index: 10;
						color: $white;
						background: $primary;
						font-weight: $font-weight-bold;
					
						& .menu-icon {
							color: $white;
						}
						& .menu-badge {
							background: rgba($black, .65);
						}
						& .menu-label {
							background: rgba($black, .65);
						}
					}
				}
			}
		}
	}
	
	& .app-sidebar-content {
		position: relative;
	}
	& .app-sidebar-minify-btn {
		margin: rem(10px) 0;
		padding: rem(5px) rem(20px) rem(5px) rem(10px) !important;
		background: darken($dark, 4%);
		color: $white;
		
		@if $enable-rtl {
			border-radius: 0 $border-radius * 5 $border-radius * 5 0;
		} @else {
			border-radius: $border-radius * 5 0 0 $border-radius * 5
		}
		
		& i {
			margin: 0 !important;
			color: $white;
		}
		&:hover,
		&:focus {
			background: $gray-900;
		}
	}
	&.app-sidebar-grid {
		& .menu {
			& > .menu-item {
				& + .menu-item {
					margin-top: -1px;
				}
				& > .menu-link {
					border-top: 1px solid lighten($dark, 7.5%);
					border-bottom: 1px solid lighten($dark, 7.5%);
				}
				
				&.expanding,
				&.expand {
					& > a {
						border-bottom-color: transparent;
					}
				}
				&.active {
					& > .menu-link {
						border-color: $gray-800;
					}
				}
			}
		}
	}
	&.app-sidebar-transparent {
		background: none;
		
		& + .app-sidebar-bg {
			background-image: var(--app-sidebar-transparent-bg-image);
			background-size: cover;
			
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba($gray-900, .5);
			}
		}
		& .menu {
			& .menu-divider {
				background: rgba($white, .25);
			}
			& > .menu-item {
				& .menu-link {
					& .menu-label,
					& .menu-badge {
						background: $white;
						color: $gray-900;
					}
					& .text-theme {
						color: $white;
					}
				}
				&.active {
					& > .menu-link {
						& .menu-caret {
							color: $white;
						}
					}
					&:not(.has-sub) {
						& > .menu-link {
							background: rgba($white, .25);
						
							& .menu-caret {
								color: $white;
							}
						}
					}
				}
			}
			& .menu-profile {
				background: rgba($black, .25);
				
				& a {
					&:hover,
					&:focus {
						background: none;
					}
				}
				& .menu-profile-cover {
					background: none;
					
					&.with-shadow {
						&:before {
							background: none;
						}
					}
				}
				&.active {
					background: rgba($black, .25);
				}
			}
		}
		& .menu-submenu {
			background: none;
			
			& > .menu-item {
				&.active {
					& > .menu-link {
						background: rgba($white, .25);
					}
				}
			}
		}
	}
}
.app-sidebar-bg {
	background: $gray-800;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: $app-sidebar-width;
	z-index: $app-sidebar-zindex - 10;
	
	@if $enable-rtl {
		left: auto;
		right: 0;
	}
	@include media-breakpoint-down(md) {
		padding-top: 0;
		left: -$app-sidebar-width;
		z-index: $app-header-zindex;
		
		@if $enable-rtl {
			left: auto;
			right: -$app-sidebar-width;
		}
	}
}
.app-sidebar-mobile-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: $app-sidebar-mobile-backdrop-zindex;
	width: 100%;
	height: 100%;
	padding: 0;
	border: 0;
	margin: 0;
	outline: none;
	display: none;
}
