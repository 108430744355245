.app-with-two-sidebar {
	& .app-header {
		& .navbar-toggle.pull-start {
			& + .navbar-header {
				margin: 0;
				justify-content: center;
			}
		}
		& .navbar-brand {
			@include media-breakpoint-down(md) {
				margin: 0 auto;
				padding: 0;
			}
		}
	}
	& .app-sidebar-end {
		&.app-sidebar,
		&.app-sidebar-bg {
			@if $enable-rtl {
				left: -$app-sidebar-width;
				right: auto;
			} @else {
				right: -$app-sidebar-width;
				left: auto;
			}
		}
	}
	&.app-with-wide-sidebar {
		& .app-sidebar-end {
			&.app-sidebar,
			&.app-sidebar-bg {
				@if $enable-rtl {
					left: -$app-sidebar-wide-width;
				} @else {
					right: -$app-sidebar-wide-width;
				}
			}
		}
	}
	&.app-sidebar-minified {
		& .app-sidebar-end {
			&.app-sidebar,
			&.app-sidebar-bg {
				width: $app-sidebar-width;
			}
		}
		&.app-with-wide-sidebar {
			& .app-sidebar-end {
				&.app-sidebar,
				&.app-sidebar-bg {
					width: $app-sidebar-wide-width;
				}
			}
		}
	}
}